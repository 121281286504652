<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="
          editedItem.bylist == '1' ||
          (editedItem.bylist == '0' && depot.NbRack > 0 && mvm_type == 2)
            ? '1600px'
            : '1000px'
        "
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>

            <v-spacer></v-spacer>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-circular
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-circular>
            </v-layout>

            <v-btn
              color="blue darken-1"
              @click="save"
              :disabled="save_disable"
              class="mx-2"
              small
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" @click.stop="close" small>
              Annuler
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="editedItem.produit_id"
                    :item-value="'produit_id'"
                    :items="articles"
                    :filter="customFilter"
                    label="Produit"
                    :readonly="!modify || form_list.length > 0"
                    @change="produit_change"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.product_code ? item.product_code + "-" : "") +
                        item.product_name
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            (item.product_code ? item.product_code + '-' : '') +
                            item.product_name
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle v-if="item.product_ref">{{
                          "Ref.: " + item.product_ref
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              {{
                                "Qte Livrée : " +
                                numberWithSpace(item.qte) +
                                " " +
                                (item.unit ? item.unit : "")
                              }}
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              {{
                                "Qte retournée : " +
                                numberWithSpace(item.qte_ret) +
                                " " +
                                (item.unit ? item.unit : "")
                              }}
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="
                    editedItem.produit_id &&
                    (mvm_type == 2 ||
                      (mvm_type == 4 && articles_list.length != 1))
                  "
                >
                  <v-autocomplete
                    v-model="editedItem.mvm_det_id"
                    :item-value="'id'"
                    :items="articles_list"
                    :filter="customFilter"
                    label="Article"
                    :readonly="!modify || form_list.length > 0"
                    @change="article_change"
                    :rules="[(v) => !!v || 'Article obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{ item.code + " " + item.label }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.label"
                        ></v-list-item-title>
                        <v-list-item-title
                          v-html="'Code : ' + item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle v-if="mvm_type == 2">{{
                          "Fournisseur : " + item.tier_name
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="2"
                  v-if="
                    editedItem.produit_id &&
                    (!editedItem.pu ||
                      $store.state.auth.includes('01027') ||
                      $store.state.isadmin)
                  "
                >
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    v-model.number="editedItem.pu"
                    readonly
                    label="P.U."
                    :rules="[(v) => !!v || 'Prix obligatoire']"
                    dense
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="6"
                  v-if="editedItem.mvm_det_id && mvm_type == 2"
                >
                  <v-autocomplete
                    ref="mvmfrom"
                    v-model="mvm_from"
                    item-value="depot_id"
                    :items="stock_depots"
                    :readonly="!modify || form_list.length > 0"
                    dense
                    label="Depot Source"
                    @change="depot_change(mvm_from)"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(
                          editedItem.article_id,
                          editedItem.mvm_det_id,
                          v
                        ) == false || 'Ligne existe!',
                    ]"
                    :loading="stock_load"
                  >
                    <template v-slot:selection="{ item }">
                      {{
                        item
                          ? item.depot_name +
                            " (" +
                            (mvm_type == 2
                              ? item.stock_cmpt_buy
                              : item.stock_cmpt) +
                            " " +
                            (editedItem.unit ? editedItem.unit : "") +
                            ")"
                          : ""
                      }}
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.depot_name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="
                            (mvm_type == 2
                              ? item.stock_cmpt_buy
                              : item.stock_cmpt) +
                            ' ' +
                            (editedItem.unit ? editedItem.unit : '')
                          "
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="6" v-if="mvm_type == 4">
                  <v-autocomplete
                    ref="mvmto"
                    v-model="mvm_to"
                    item-value="id"
                    item-text="label"
                    :items="depots"
                    :readonly="!modify"
                    label="Depot de Destination"
                    @change="depot_change(mvm_to)"
                    :rules="[
                      (v) => !!v || 'Depot obligatoire',
                      (v) =>
                        ligne_exist(
                          editedItem.article_id,
                          editedItem.mvm_det_id,
                          v
                        ) == false || 'Ligne existe!',
                    ]"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="depot.id">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        autocomplete="off"
                        ref="qte"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="editedItem.qte"
                        :label="
                          'Quantité' +
                          (editedItem.unit ? ' (' + editedItem.unit + ')' : '')
                        "
                        :rules="[
                          (v) => !!v || 'Quantité obligatoire',
                          (v) => v > 0 || 'Valeur incorrecte ',
                          (v) =>
                            depot.stock_cmpt_buy >= v ||
                            !modify ||
                            mvm_type == 4 ||
                            'Stock depot ' + depot.stock_cmpt_buy,
                          (v) =>
                            parseFloat(v) <= parseFloat(qte_max) ||
                            !modify ||
                            'Maximum autorisé ' + qte_max,
                        ]"
                        v-bind="attrs"
                        v-on="on"
                        :readonly="
                          editedItem.bylist == '1' ||
                          (editedItem.bylist == '0' && depot.NbRack > 0) ||
                          !modify
                        "
                        @keydown.enter="save"
                        hide-spin-buttons
                        :disabled="(!mvm_to && !mvm_from) || save_disable"
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </template>

                    <li>
                      {{
                        "Quantité Retournée " +
                        +numberWithSpace(
                          qte_ret ? qte_ret.toFixed(precision) : 0
                        )
                      }}
                    </li>
                  </v-tooltip>
                </v-col>

                <v-col cols="12" sm="1" md="12">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="6"
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' &&
                        depot.NbRack > 0 &&
                        mvm_type == 2)) &&
                    editedItem.mvm_det_id > 0 &&
                    ((mvm_type == 4 && mvm_to > 0) ||
                      (mvm_type == 2 && mvm_from > 0))
                  "
                >
                  <v-row>
                    <v-col cols="12" sm="1" md="11">
                      <listitems
                        :list="flist"
                        :headers="
                          editedItem.bylist == '1' ? colisheaders : boxheaders_d
                        "
                        :title="list_title"
                        :master="true"
                        :add="false"
                        :del="false"
                        :Update="false"
                        :showstd="true"
                        :showedit="true"
                        :Total="true"
                        :print="true"
                        :exp_excel="true"
                        :imp_excel="false"
                        :key="kflist"
                        :multiple="true"
                        @selected_rows="selected_left_change"
                        :laoding="productload"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="add_product"
                        class="my-2"
                        small
                        :disabled="selected_left.length == 0"
                      >
                        >
                      </v-btn>

                      <v-btn
                        fab
                        color="blue darken-1"
                        @click="del_product"
                        class="my-2"
                        small
                        :disabled="selected_right.length == 0"
                      >
                        <span v-html="'<'"></span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  :sm="
                    editedItem.bylist == '1' ||
                    (editedItem.bylist == '0' &&
                      depot.NbRack > 0 &&
                      mvm_type == 2)
                      ? 6
                      : 12
                  "
                  :md="
                    editedItem.bylist == '1' ||
                    (editedItem.bylist == '0' &&
                      depot.NbRack > 0 &&
                      mvm_type == 2)
                      ? 6
                      : 12
                  "
                  v-if="
                    (editedItem.bylist == '1' ||
                      (editedItem.bylist == '0' && depot.NbRack > 0)) &&
                    editedItem.mvm_det_id > 0 &&
                    ((mvm_type == 4 && mvm_to > 0) ||
                      (mvm_type == 2 && mvm_from > 0))
                  "
                >
                  <listitems
                    :list="form_list"
                    :headers="
                      editedItem.bylist == '1' ? colisheaders : boxheaders_s
                    "
                    :title="'Selected'"
                    :master="true"
                    :add="editedItem.bylist == '0' && mvm_type == 4"
                    :del="false"
                    :Update="true"
                    :showstd="true"
                    :Total="true"
                    :print="true"
                    :exp_excel="true"
                    :imp_excel="false"
                    :key="frml"
                    @delete="del_item"
                    :multiple="!(editedItem.bylist == '0' && mvm_type == 4)"
                    @selected_rows="selected_right_change"
                    :laoding="listload"
                    :ipg="5"
                  >
                  </listitems>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
const confirmdialog = () => import("./ConfirmDialog.vue");
const listitems = () => import("./ListItems.vue");
import MVM_DETAIL_UPDATE from "../graphql/Mouvment/MVM_DETAIL_UPDATE.gql";
import PRODUCT_LIST_MVM from "../graphql/Product/PRODUCT_LIST_MVM.gql";
import ALLARTICLES_DEPOT from "../graphql/Depot/ALLARTICLES_DEPOT.gql";
import { colisbl } from "print/data.js";
export default {
  components: { confirmdialog, listitems },
  name: "mvmretform",
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    mvm_type: Number,
    attributes: Array,
    articles: Array,
    mvm: Object,
    modify: Boolean,
    boxs: Array,
    units: Array,
  },
  data: () => ({
    kflist: 100,
    adding: false,
    deleting: false,
    productload: false,
    save_disable: false,
    imp: false,
    title: "Liste Détaillée",
    listload: false,
    isListClose: true,
    list: false,
    datepicker1: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {
      id: -1,
    },
    editedItem: {},
    combovalue: "",
    list_title: "Disponible",
    isProductClosed: true,
    liv: {},
    depot: {},
    qte: 0,
    qte_liv: 0,
    qte_ret: 0,
    qte_rst: 0,
    qte_max: 0,
    qte_depot: 0,
    tol: 0,
    mvm_to: "",
    mvm_from: "",
    precision: 2,
    stock_load: false,
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Code",
        value: "ref",
        slot: "href",
        selected: true,
      },
      {
        text: "Article",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Date de péremption",
        value: "expire_date",
        selected: true,
        slot: "date",
        hiden: true,
      },
      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        hiden: true,
        edit: false,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        default: "",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],

    boxheaders_d: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Rack",
        value: "rack",
        selected: true,
      },
      {
        text: "Box",
        value: "box",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    boxheaders_s: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Rack",
        value: "rack",
        hiden: true,
        selected: true,
      },
      {
        text: "Box",
        value: "box_id",
        slot: "simple-select",
        selected: true,
        edit: false,
        type: "autocomplete",
        item_value: "id",
        item_text: "box",
        subtitle1: "rack",
        subtitle1_label: "Rack",
        subtitle2: "stock_cmpt",
        subtitle2_label: "Stock",
        subtitle3: "nbarticle",
        subtitle3_label: "Nb : ",
        select_list: [],
        show_list: [],
        default: "",
        sm: 6,
        md: 6,
        rules: [(v) => !!v || "Box obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "href22",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
    ],
    colisheaders: [],
    form_list: [],
    prdslist: [],
    selected_left: [],
    selected_right: [],
    stock_depots: [],
    stock_boxs: [],
    frml: 600,
    cs: 0,
    linked: false,
  }),

  computed: {
    depots() {
      return this.$store.state.depots.filter(
        (elm) => elm.ChildCount == 0 && elm.type_id == 2
      );
    },
    colis_unit() {
      let c = "item";
      let i = this.units.findIndex(
        (elm) => elm.id == this.editedItem.fk_unit_colis
      );
      if (i >= 0) c = this.units[i].name;
      return c;
    },
    boxs_list() {
      let list = [];
      if (this.boxs)
        list = this.boxs.filter(
          (elm) =>
            elm.depot_id == (this.mvm_type == 4 ? this.mvm_to : this.mvm_from)
        );
      return list;
    },
    articles_list() {
      let list = [];
      if (this.articles) {
        list = this.articles.filter(
          (elm) => elm.produit_id == this.editedItem.produit_id
        );
      }
      return list;
    },
    articles_link() {
      let list = [];
      if (this.articles) {
        let l = this.articles.filter(
          (elm) => elm.produit_id == this.editedItem.produit_id
        );
        if (l.length > 0) {
          if (l[0].links.length > 0) list = l[0].links;
        }
      }
      return list;
    },
    flist() {
      let l = [];
      let list =
        this.editedItem.bylist == "1"
          ? this.prdslist
            ? this.prdslist
            : []
          : this.stock_boxs;
      l = list.filter(
        (elm) => !this.form_list.map((elm3) => elm3.id).includes(elm.id)
      );
      return l;
    },
    sumlist_selected() {
      let list = [];
      list = this.form_list ? this.form_list : [];
      let v_min = 0;

      if (this.articles_link.length > 0) {
        let l = [];
        this.articles_link.forEach((element) => {
          let v =
            list
              .filter((elm) => elm.article_id == element.link_id)
              .reduce((a, b) => a + (b["qte"] || 0), 0) / element.ratio;
          l.push(v / element.ratio);
        });
        v_min = Math.min(...l);
      } else v_min = list.reduce((a, b) => a + (b["qte"] || 0), 0);
      return parseFloat(v_min.toFixed(this.precision));
    },

    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    showDetail() {
      return this.showForm;
    },
    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {
    form_list: {
      handler() {
        if (
          this.editedItem.bylist == "1" ||
          (this.editedItem.bylist == "0" && this.depot.NbRack > 0)
        ) {
          if (this.editedItem.id < 0)
            this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
          if (
            colisbl &&
            this.editedItem.bylist == "1" &&
            this.editedItem.id < 0
          ) {
            this.editedItem.description = "";
            this.editedItem.colis_json = [];
            if (this.form_list) {
              this.form_list.forEach((element) => {
                if (this.editedItem.colis_json) {
                  let i = this.editedItem.colis_json.findIndex(
                    (elm) =>
                      elm.article_id == element.article_id &&
                      elm.qte == element.qte
                  );
                  if (i >= 0)
                    this.editedItem.colis_json[i].nb =
                      this.editedItem.colis_json[i].nb + 1;
                  else
                    this.editedItem.colis_json.push({
                      article_id: element.article_id,
                      label: element.label,
                      qte: element.qte,
                      nb: 1,
                    });
                }
              });
            }
            let unit = this.editedItem.unit ? this.editedItem.unit : "";
            if (this.mvm_type == 2 && this.editedItem.unit_buy)
              unit = this.editedItem.unit_buy;
            this.editedItem.description = this.get_desc(
              this.editedItem.colis_json,
              this.editedItem.qte,
              this.colis_unit,
              unit
            );
          }
        }
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.defaultItem);
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        if (this.mvm_type == 4) this.mvm_to = this.editedItem.depot_id;
        if (this.mvm_type == 2) this.mvm_from = this.editedItem.depot_id;
        await this.article_change();
        this.depot_change(this.editedItem.depot_id);
      } else {
        if (this.mvm_type == 4 && this.depots.length == 1) {
          this.mvm_to = this.depots[0].id;
          this.depot_change(this.mvm_to);
        }

        if (this.mvm_type == 4 && this.mvm.motif_ret == 2) {
          let j = this.depots.findIndex((elm) => elm.conforme == 1);
          if (j >= 0) {
            this.mvm_to = this.depots[j].id;
            this.depot_change(this.mvm_to);
          }
        }
      }
    }
  },

  methods: {
    get_desc(list, qte, colis_unit, unit) {
      let desc = "";
      if (list.length > 0) {
        var unique = list.map((elm) => elm.article_id).filter(onlyUnique);
        if (unique.length > 1) {
          unique.forEach((elm2) => {
            let l = list.filter((elm) => elm.article_id == elm2);
            if (l.length > 0) {
              desc = desc + " " + l[0].label + "\n";
              list
                .filter((elm) => elm.article_id == l[0].article_id)
                .forEach((element) => {
                  desc =
                    desc +
                    element.nb.toString() +
                    " " +
                    colis_unit +
                    (element.nb > 1 ? "s" : "") +
                    " / " +
                    element.qte.toFixed(this.precision) +
                    " " +
                    unit +
                    " " +
                    "\n";
                });
            }
          });
        } else
          list.forEach((element) => {
            desc =
              desc +
              element.nb.toString() +
              " " +
              colis_unit +
              (element.nb > 1 ? "s" : "") +
              " / " +
              element.qte.toFixed(this.precision) +
              " " +
              unit +
              " " +
              "\n";
          });
        if (list.length > 0 && unique.length == 1)
          desc =
            desc +
            "Soit " +
            list.reduce((a, b) => a + (b["nb"] || 0), 0) +
            " " +
            colis_unit +
            "s pour un total de " +
            qte +
            " " +
            unit;
      }
      return desc;
    },
    async produit_change() {
      let i = this.articles.findIndex(
        (elm) => elm.produit_id == this.editedItem.produit_id
      );
      if (i >= 0) {
        if (this.articles[i].links.length > 0) {
          this.linked = true;
        }

        this.editedItem.factor = this.articles[i].factor
          ? this.articles[i].factor
          : 1;
        this.editedItem.fk_unit = this.articles[i].fk_unit;
        this.editedItem.unit_name = this.articles[i].unit_name;
        this.editedItem.is_expire = this.articles[i].is_expire;
        this.editedItem.unit = this.articles[i].unit;
        this.editedItem.unit_buy = this.articles[i].unit_buy;
        this.editedItem.bylist = this.articles[i].bylist;
        this.precision = this.articles[i].decimal;
        if (this.mvm_type == 4)
          this.editedItem.unit_name = this.articles[i].unit_name;
        this.editedItem.product_name = this.articles[i].label;
        this.editedItem.colisattributs = this.articles[i].colisattributs;
      }

      if (this.articles_list.length == 1 && this.editedItem.id < 0) {
        this.editedItem.mvm_det_id = this.articles_list[0].id;
        this.article_change();
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      ["ref", "code", "label", "nomvm", "nobon", "opr_date", "mvm_ref"].forEach(
        (element) => {
          const text1 = item[element] ? item[element].toLowerCase() : "";
          textlist.push(text1);
        }
      );

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async depot_change(d) {
      this.form_list = [];
      if (this.editedItem.id < 0) this.editedItem.qte = 0;
      let i = this.depots.findIndex((elm) => elm.id == d);
      if (i >= 0) {
        this.depot = this.depots[i];

        if (this.mvm_type == 2) {
          let j = this.stock_depots.findIndex((elm) => elm.depot_id == d);
          if (j >= 0) {
            this.depot.stock_cmpt_buy = this.stock_depots[j].stock_cmpt_buy;
            if (this.depot.NbRack > 0)
              this.stock_boxs = this.stock_depots[j].stock_boxs.filter(
                (elm) => elm.depot_id == d
              );
            this.kflist++;
          }
        }
        this.list_title =
          this.editedItem.bylist == "1" ? "Disponible" : this.depot.box;
        this.colisheaders2[5].text = this.depot.box ? this.depot.box : "Box";
        this.colisheaders2[5].rules = [
          (v) => !!v || this.depot.box + " obligatoire",
        ];
        this.colisheaders2[5].subtitle1_label = this.depot.rack
          ? this.depot.rack
          : "Rack";
        this.colisheaders2[5].hiden = this.depot.NbRack == 0;
        this.colisheaders2[5].edit =
          this.depot.NbRack > 0 && this.mvm_type == 4;
        this.colisheaders2[5].select_list = this.boxs_list;
        this.colisheaders2[5].show_list = this.boxs_list;
        //retour fournisseur
        let u = "";
        let z;
        if (this.units)
          z = this.units.findIndex((elm) => elm.id == this.editedItem.fk_unit);
        if (z >= 0) u = "(" + this.units[z].name + ")";
        this.boxheaders_d[1].text = this.depot.rack ? this.depot.rack : "Rack";
        this.boxheaders_s[1].text = this.depot.rack ? this.depot.rack : "Rack";
        this.boxheaders_s[1].hiden =
          this.mvm_type == 4 && this.editedItem.bylist == "0";
        this.boxheaders_d[2].text = this.depot.box ? this.depot.box : "Box";
        this.boxheaders_s[2].text = this.depot.box ? this.depot.box : "Box";
        this.boxheaders_s[2].edit =
          this.depot.NbRack > 0 &&
          this.mvm_type == 4 &&
          this.editedItem.bylist == "0";
        this.boxheaders_d[3].text = "Quantité " + u;
        this.boxheaders_d[3].dec = this.precision;
        this.boxheaders_s[3].text = "Quantité " + u;
        this.boxheaders_s[3].dec = this.precision;
        //

        this.boxheaders_s[2].text = this.colisheaders2[5].text;
        this.boxheaders_s[2].rules = this.colisheaders2[5].rules;
        this.boxheaders_s[2].subtitle1_label =
          this.colisheaders2[5].subtitle1_label;

        this.boxheaders_s[2].show_list = this.boxs_list;

        if (
          this.editedItem.bylist == "0" &&
          this.depot.NbRack > 0 &&
          this.editedItem.id > 0
        ) {
          this.form_list = this.editedItem.boxs;
          this.frml++;
        }
      }
      this.boxheaders_s[2].select_list = this.boxs_list.filter(
        (elm) => !this.form_list.map((elm) => elm.box_id).includes(elm.id)
      );
      //this.$refs.form.resetValidation();
      this.frml++;
      if (this.editedItem.bylist == "1") {
        this.init_list();
        if (this.editedItem.id > 0) await this.list_mvm();
        await this.load_product_list();
      } else if (this.$refs.qte) this.$refs.qte.focus();
    },
    selected_left_change(items) {
      this.selected_left = items;
    },
    selected_right_change(items) {
      this.selected_right = items;
    },
    add_product() {
      if (this.selected_left.length > 0) {
        this.selected_right = [];
        this.selected_left.forEach((element) => {
          element.selected = false;
          this.form_list.unshift(element);
        });
        this.selected_left = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },

    del_product() {
      if (this.selected_right) {
        this.selected_left = [];
        this.selected_right.forEach((element) => {
          element.selected = false;
          this.form_list.splice(
            this.form_list.findIndex((elm) => elm.id == element.id),
            1
          );
        });
        this.selected_right = [];
        this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
        this.frml++;
        this.kflist++;
      }
    },
    del_item() {
      this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
    },

    init_list() {
      this.colisheaders = [];
      this.colisheaders2[4].hiden = this.editedItem.is_expire == "0";
      this.colisheaders2[2].hiden = this.articles_link.length == 0;
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      this.colisheaders[3].text = this.editedItem.unit_name;
      this.colisheaders[3].rules = [
        (v) => !!v || this.editedItem.unit_name + " obligatoire",
      ];
      this.colisheaders[3].dec = this.precision;
      this.colisheaders[3].edit = this.mvm_type == 4;
      let colis_attributs = [];
      colis_attributs = this.editedItem.colisattributs
        ? this.editedItem.colisattributs
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: [
              (v) =>
                !!v ||
                (this.attributs_list[i].caption
                  ? this.attributs_list[i].caption
                  : this.attributs_list[i].name) + " obligatoire",
            ],
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 2, 0, elm);
        }
      }
    },
    async load_product_list() {
      //list possible

      this.productload = true;
      let v;
      let r;

      v = {
        article_id:
          this.articles_link.length > 0
            ? this.articles_link.map((elm) => elm.link_id)
            : [this.editedItem.article_id],
        // tier_id:
        //   this.mvm_type == 2 || this.mvm_type == 4 ? this.mvm.tier_id : null,
        //mvm_type: this.mvm_type == 4 ? 3 : 1,
        depot_id: this.mvm_type == 2 ? this.mvm_from : null,
        buy: this.mvm_type == 2 ? 1 : null,
        stock_id: this.mvm_type == 2 ? 1 : 0,
        mvm_detail_id: this.editedItem.mvm_det_id,
      };
      r = await this.requette(PRODUCT_LIST_MVM, v);
      if (r) this.prdslist = r.product_list_mvm;

      this.productload = false;
      this.kflist++;
    },
    async list_mvm() {
      this.form_list = [];

      if (this.editedItem.id >= 0) {
        this.listload = true;
        let v = {
          mvm_detail_id: this.editedItem.id,
          buy: this.mvm_type == 2 ? 1 : null,
        };

        let r = await this.requette(PRODUCT_LIST_MVM, v);
        if (r) {
          this.form_list = r.product_list_mvm;
          this.frml++;
          this.editedItem.qte = this.sumlist_selected.toFixed(this.precision);
          this.listload = false;
        }
      }
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    numberWithSpace: function (x) {
      return x
        ? x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
        : "0";
    },

    datefr(date, time = false, long = true) {
      let t = "";
      let option = {
        year: "numeric",
        month: long ? "long" : "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async requette(query, v) {
      this.loading = true;
      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ligne_exist(p, l, d) {
      let i = this.items.filter((elm) => elm.id != this.item.id);
      let e = -1;
      if (p && d && l)
        e = i.findIndex(
          (elm) =>
            elm.article_id == p && elm.mvm_det_id == l && elm.depot_id == d
        );
      return e != -1;
    },

    async article_change() {
      this.stock_depots = [];

      if (this.mvm_type == 2) {
        let i = this.articles_list.findIndex(
          (elm) => elm.id == this.editedItem.mvm_det_id
        );
        if (i >= 0) {
          this.editedItem.article_id = this.articles_list[i].article_id;
          let v = {
            where: {
              AND: [
                {
                  column: "ARTICLE_ID",
                  value: this.editedItem.article_id,
                },
              ],
            },
          };
          this.stock_load = true;
          let r = await this.requette(ALLARTICLES_DEPOT, v);

          this.stock_load = false;
          if (r) {
            if (r.allarticles_depot.length > 0)
              this.stock_depots = r.allarticles_depot.filter(
                (elm) => elm.type_id == 2
              );
            if (this.stock_depots.length == 1 && this.editedItem.id < 0) {
              this.mvm_from = this.stock_depots[0].depot_id;
              this.depot_change(this.mvm_from);
            }
          }
        }
      }

      let qte_tmp = 0;
      this.items.forEach((element) => {
        if (element.mvm_det_id == this.editedItem.mvm_det_id)
          qte_tmp = qte_tmp + parseFloat(element.qte);
      });
      let i = this.articles_list.findIndex(
        (elm) => elm.id == this.editedItem.mvm_det_id
      );
      if (i >= 0) {
        this.qte_ret = this.articles_list[i].qte_ret;
        this.editedItem.article_id = this.articles_list[i].article_id;
        this.editedItem.qte_ret = this.articles_list[i].qte_ret;
        this.editedItem.cmd_det_id = this.articles_list[i].cmd_det_id;
        if (this.editedItem.id < 0) this.editedItem.qte = 0;
        this.editedItem.pu = this.articles_list[i].pu;

        this.editedItem.pmp = this.articles_list[i].pmp;
        this.editedItem.tva_tx = this.articles_list[i].tva_tx;

        this.qte = this.articles_list[i].qte;

        if (this.mvm_type == 4)
          this.editedItem.fk_unit = this.articles_list[i].fk_unit;

        this.qte_max =
          this.qte +
          (this.editedItem.qte ? this.editedItem.qte : 0) -
          qte_tmp -
          this.editedItem.qte_ret;
        if (this.mvm_type == 4 && this.mvm_to && this.editedItem.id < 0) {
          //  this.mvm_to = this.articles_list[i].depot_id;
          this.depot_change(this.mvm_to);
        }
      }
    },
    async close() {
      this.$emit("close", this.items);
    },
    verifie_attribut() {
      let ok = true;
      let l1 =
        this.editedItem.bylist == "1" ? this.colisheaders : this.boxheaders_s;
      let l = l1.filter((elm) => elm.edit == true && elm.hiden != true);
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        for (let i = 0; i < l.length; i++) {
          if (!element[l[i].value] && l[i].value != "comment") {
            this.snackbar_text =
              l[i].text + " manquant! Ligne " + (parseInt(index) + 1);
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (ok && this.editedItem.is_expire == "1" && !element.expire_date) {
          this.snackbar_text =
            "Date péremption manquante! Ligne " + (parseInt(index) + 1);
          this.snackbar_color = "error";
          this.snackbar = true;
          ok = false;
          break;
        }
      }
      return ok;
    },
    async verif_amalgame() {
      let ok = true;
      let a = "";
      this.articles_link.forEach((element) => {
        let v = this.form_list
          .filter((elm) => elm.article_id == element.link_id)
          .reduce((a, b) => a + (b["qte"] || 0), 0);
        let d = v / element.ratio - this.editedItem.qte;
        if (d > 0) {
          ok = false;
          a = a + "Article : " + element.label + " Quantité : " + d + "\n";
        }
      });
      if (a.length > 0) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Attention Excédent de Produits !! Etês-vous sûr ?",
            "",
            a
          )
        )
          ok = true;
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;
        if (this.articles_link.length > 0 && ok)
          ok = await this.verif_amalgame();
        //       ok = this.verifie_attribut();
        if (ok) {
          this.save_disable = true;
          let pl = [];
          let products_list = [];
          let boxs = [];
          if (this.editedItem.bylist == "1") {
            this.form_list.forEach((element) => {
              products_list.push({
                fk_produit_list: element.id,
                qte: element.qte,
              });
              pl.push({
                id: element.id,
                ref: element.ref,
                ref0: element.ref0,
                ref1: element.ref1,
                ref2: element.ref2,
                ref3: element.ref3,
                ref4: element.ref4,
                ref5: element.ref5,
                transf_box_id: element.box_id,
              });
            });
          }
          if (this.editedItem.bylist == "0" && this.depot.NbRack > 0) {
            for (let index = 0; index < this.form_list.length; index++) {
              const element = this.form_list[index];
              let elm = {};
              elm.article_id = this.editedItem.article_id;
              elm.box_id = element.box_id;
              elm.qte = element.qte;

              boxs.push(elm);
              //
            }
          }
          let i;
          i = this.articles_list.findIndex(
            (elm) => elm.id == this.editedItem.mvm_det_id
          );
          if (i >= 0) {
            this.editedItem.product_name = this.articles_list[i].label;
          }
          let v;
          v = {
            mvmdetail: [
              {
                mvm_id: this.item.mvm_id,
                pu: this.editedItem.pu,
                qte: parseFloat(this.editedItem.qte),
                tva_tx: this.editedItem.tva_tx,
                fk_unit: this.editedItem.fk_unit,
                factor: this.editedItem.factor,
                remise: parseFloat(this.editedItem.remise),
                label: this.mvm_type == 4 ? this.editedItem.label : null,
                description: this.editedItem.description,
                gamme_id: this.editedItem.gamme_id,
                depot_id: this.mvm_type == 2 ? this.mvm_from : this.mvm_to,
                colis_json: this.editedItem.colis_json
                  ? JSON.stringify(this.editedItem.colis_json)
                  : null,
                article_id: this.editedItem.article_id,
                mvm_det_id: this.editedItem.mvm_det_id,
                cmd_det_id: this.editedItem.cmd_det_id,
                comment: this.editedItem.comment,
                write_uid: this.$store.state.me.id,
              },
            ],
            id: this.editedItem.id > 0 ? this.editedItem.id : null,
            factor: this.editedItem.factor,
            products_list: products_list.length > 0 ? products_list : null,
            pl: pl.length > 0 ? pl : null,
            boxs: boxs.length > 0 ? boxs : null,
          };

          let r = await this.maj(MVM_DETAIL_UPDATE, v);
          if (r) {
            if (this.editedItem.id > 0)
              this.items.splice(this.item.index, 1, this.editedItem);
            else {
              this.editedItem.id = r.UpdateMvmDetail;
              this.items.push(this.editedItem);
            }
            this.$emit("change", this.editedItem);
            this.$store.dispatch("Changed", true);
            this.$emit("close", this.items);
          } else {
            this.snackbar_text = " erreur d'enregistrement";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
          this.save_disable = false;
        }
      }
    },
  },
};
</script>
