var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":_vm.editedItem.bylist == '1' ||
        (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0 && _vm.mvm_type == 2)
          ? '1600px'
          : '1000px',"persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue darken-1","disabled":_vm.save_disable,"small":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]),_c('v-btn',{attrs:{"color":"blue darken-1","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Annuler ")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"item-value":'produit_id',"items":_vm.articles,"filter":_vm.customFilter,"label":"Produit","readonly":!_vm.modify || _vm.form_list.length > 0,"rules":[function (v) { return !!v || 'Produit obligatoire'; }],"dense":""},on:{"change":_vm.produit_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s((item.product_code ? item.product_code + "-" : "") + item.product_name)+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                          (item.product_code ? item.product_code + '-' : '') +
                          item.product_name
                        )}}),(item.product_ref)?_c('v-list-item-subtitle',[_vm._v(_vm._s("Ref.: " + item.product_ref))]):_vm._e(),_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_vm._v(" "+_vm._s("Qte Livrée : " + _vm.numberWithSpace(item.qte) + " " + (item.unit ? item.unit : ""))+" ")])],1)],1),_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_vm._v(" "+_vm._s("Qte retournée : " + _vm.numberWithSpace(item.qte_ret) + " " + (item.unit ? item.unit : ""))+" ")])],1)],1)],1)]}}]),model:{value:(_vm.editedItem.produit_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "produit_id", $$v)},expression:"editedItem.produit_id"}})],1),(
                  _vm.editedItem.produit_id &&
                  (_vm.mvm_type == 2 ||
                    (_vm.mvm_type == 4 && _vm.articles_list.length != 1))
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-autocomplete',{attrs:{"item-value":'id',"items":_vm.articles_list,"filter":_vm.customFilter,"label":"Article","readonly":!_vm.modify || _vm.form_list.length > 0,"rules":[function (v) { return !!v || 'Article obligatoire'; }],"dense":""},on:{"change":_vm.article_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.code + " " + item.label)+" ")]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.label)}}),_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('Code : ' + item.code)}}),(_vm.mvm_type == 2)?_c('v-list-item-subtitle',[_vm._v(_vm._s("Fournisseur : " + item.tier_name))]):_vm._e()],1)]}}],null,false,539177663),model:{value:(_vm.editedItem.mvm_det_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "mvm_det_id", $$v)},expression:"editedItem.mvm_det_id"}})],1):_vm._e(),(
                  _vm.editedItem.produit_id &&
                  (!_vm.editedItem.pu ||
                    _vm.$store.state.auth.includes('01027') ||
                    _vm.$store.state.isadmin)
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","readonly":"","label":"P.U.","rules":[function (v) { return !!v || 'Prix obligatoire'; }],"dense":"","hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.pu),callback:function ($$v) {_vm.$set(_vm.editedItem, "pu", _vm._n($$v))},expression:"editedItem.pu"}})],1):_vm._e(),(_vm.editedItem.mvm_det_id && _vm.mvm_type == 2)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('v-autocomplete',{ref:"mvmfrom",attrs:{"item-value":"depot_id","items":_vm.stock_depots,"readonly":!_vm.modify || _vm.form_list.length > 0,"dense":"","label":"Depot Source","rules":[
                    function (v) { return !!v || 'Depot obligatoire'; },
                    function (v) { return _vm.ligne_exist(
                        _vm.editedItem.article_id,
                        _vm.editedItem.mvm_det_id,
                        v
                      ) == false || 'Ligne existe!'; } ],"loading":_vm.stock_load},on:{"change":function($event){return _vm.depot_change(_vm.mvm_from)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item ? item.depot_name + " (" + (_vm.mvm_type == 2 ? item.stock_cmpt_buy : item.stock_cmpt) + " " + (_vm.editedItem.unit ? _vm.editedItem.unit : "") + ")" : "")+" ")]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.depot_name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                          (_vm.mvm_type == 2
                            ? item.stock_cmpt_buy
                            : item.stock_cmpt) +
                          ' ' +
                          (_vm.editedItem.unit ? _vm.editedItem.unit : '')
                        )}})],1)]}}],null,false,1410958180),model:{value:(_vm.mvm_from),callback:function ($$v) {_vm.mvm_from=$$v},expression:"mvm_from"}})],1):_vm._e(),(_vm.mvm_type == 4)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('v-autocomplete',{ref:"mvmto",attrs:{"item-value":"id","item-text":"label","items":_vm.depots,"readonly":!_vm.modify,"label":"Depot de Destination","rules":[
                    function (v) { return !!v || 'Depot obligatoire'; },
                    function (v) { return _vm.ligne_exist(
                        _vm.editedItem.article_id,
                        _vm.editedItem.mvm_det_id,
                        v
                      ) == false || 'Ligne existe!'; } ],"dense":""},on:{"change":function($event){return _vm.depot_change(_vm.mvm_to)}},model:{value:(_vm.mvm_to),callback:function ($$v) {_vm.mvm_to=$$v},expression:"mvm_to"}})],1):_vm._e(),(_vm.depot.id)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":'Quantité' +
                        (_vm.editedItem.unit ? ' (' + _vm.editedItem.unit + ')' : ''),"rules":[
                        function (v) { return !!v || 'Quantité obligatoire'; },
                        function (v) { return v > 0 || 'Valeur incorrecte '; },
                        function (v) { return _vm.depot.stock_cmpt_buy >= v ||
                          !_vm.modify ||
                          _vm.mvm_type == 4 ||
                          'Stock depot ' + _vm.depot.stock_cmpt_buy; },
                        function (v) { return parseFloat(v) <= parseFloat(_vm.qte_max) ||
                          !_vm.modify ||
                          'Maximum autorisé ' + _vm.qte_max; } ],"readonly":_vm.editedItem.bylist == '1' ||
                        (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0) ||
                        !_vm.modify,"hide-spin-buttons":"","disabled":(!_vm.mvm_to && !_vm.mvm_from) || _vm.save_disable},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)},"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", _vm._n($$v))},expression:"editedItem.qte"}},'v-text-field',attrs,false),on))]}}],null,false,794495292)},[_c('li',[_vm._v(" "+_vm._s("Quantité Retournée " + +_vm.numberWithSpace( _vm.qte_ret ? _vm.qte_ret.toFixed(_vm.precision) : 0 ))+" ")])])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"12"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1),(
                  (_vm.editedItem.bylist == '1' ||
                    (_vm.editedItem.bylist == '0' &&
                      _vm.depot.NbRack > 0 &&
                      _vm.mvm_type == 2)) &&
                  _vm.editedItem.mvm_det_id > 0 &&
                  ((_vm.mvm_type == 4 && _vm.mvm_to > 0) ||
                    (_vm.mvm_type == 2 && _vm.mvm_from > 0))
                )?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"1","md":"11"}},[_c('listitems',{key:_vm.kflist,attrs:{"list":_vm.flist,"headers":_vm.editedItem.bylist == '1' ? _vm.colisheaders : _vm.boxheaders_d,"title":_vm.list_title,"master":true,"add":false,"del":false,"Update":false,"showstd":true,"showedit":true,"Total":true,"print":true,"exp_excel":true,"imp_excel":false,"multiple":true,"laoding":_vm.productload,"ipg":5},on:{"selected_rows":_vm.selected_left_change}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-btn',{staticClass:"my-2",attrs:{"fab":"","color":"blue darken-1","small":"","disabled":_vm.selected_left.length == 0},on:{"click":_vm.add_product}},[_vm._v(" > ")]),_c('v-btn',{staticClass:"my-2",attrs:{"fab":"","color":"blue darken-1","small":"","disabled":_vm.selected_right.length == 0},on:{"click":_vm.del_product}},[_c('span',{domProps:{"innerHTML":_vm._s('<')}})])],1)],1)],1):_vm._e(),(
                  (_vm.editedItem.bylist == '1' ||
                    (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0)) &&
                  _vm.editedItem.mvm_det_id > 0 &&
                  ((_vm.mvm_type == 4 && _vm.mvm_to > 0) ||
                    (_vm.mvm_type == 2 && _vm.mvm_from > 0))
                )?_c('v-col',{attrs:{"cols":"12","sm":_vm.editedItem.bylist == '1' ||
                  (_vm.editedItem.bylist == '0' &&
                    _vm.depot.NbRack > 0 &&
                    _vm.mvm_type == 2)
                    ? 6
                    : 12,"md":_vm.editedItem.bylist == '1' ||
                  (_vm.editedItem.bylist == '0' &&
                    _vm.depot.NbRack > 0 &&
                    _vm.mvm_type == 2)
                    ? 6
                    : 12}},[_c('listitems',{key:_vm.frml,attrs:{"list":_vm.form_list,"headers":_vm.editedItem.bylist == '1' ? _vm.colisheaders : _vm.boxheaders_s,"title":'Selected',"master":true,"add":_vm.editedItem.bylist == '0' && _vm.mvm_type == 4,"del":false,"Update":true,"showstd":true,"Total":true,"print":true,"exp_excel":true,"imp_excel":false,"multiple":!(_vm.editedItem.bylist == '0' && _vm.mvm_type == 4),"laoding":_vm.listload,"ipg":5},on:{"delete":_vm.del_item,"selected_rows":_vm.selected_right_change}})],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('confirmdialog',{ref:"confirm"}),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }